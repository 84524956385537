// this file is used to track the utm parameters and store them in a cookie called raylo_tracking
// This script is imported into the page by google tag manager
// https://tagmanager.google.com/#/container/accounts/4701842817/containers/11775648/workspaces/1000201/tags/16

type CookieItem = Record<string, string | undefined>;
type SharedProperties = {
  extractedAt: string;
  userAgent: string;
  referrerUrl?: string;
};

const TRACKING_COOKIE_NAME = 'raylo_tracking';

const trackingKeys = {
  ANALYTICS: 'ANALYTICS',
  ORGANIC_SEARCH: 'ORGANIC_SEARCH',
};

// ------ Functions -------

function constructGoogleTracking(params: CookieItem) {
  const googleAnalyticsId = getCookie('_ga');
  const experimentParam = params.utm_expid;

  if (!googleAnalyticsId && !experimentParam) {
    return {};
  }

  const existingGoogleTracking = fetchExistingCollection(
    TRACKING_COOKIE_NAME,
  ).find((element) => element.category === trackingKeys.ANALYTICS);

  // If googleTracking does not exist already or experimentParam is present only then create a googleTracking
  if (existingGoogleTracking && !experimentParam) {
    return {};
  }

  const tracking = {
    category: trackingKeys.ANALYTICS,
    provider: 'google',
    visitorReference: googleAnalyticsId,
    contentReference: experimentParam,
    sourceReference: 'raylo',
    extractedAt: new Date().toJSON(),
  };

  compactObject(tracking);
  return tracking;
}

// Shared properties for all providers
function getSharedProperties() {
  const sharedProperties: SharedProperties = {
    extractedAt: new Date().toJSON(),
    // encode the user agent and referrer to avoid issues with special characters.
    // This will be automatically decoded when reading the cookie.
    userAgent: encodeURIComponent(navigator.userAgent),
  };
  if (document.referrer) {
    sharedProperties.referrerUrl = encodeURIComponent(document.referrer);
  }
  return sharedProperties;
}

function canStoreOrganicTrackingBasedOnReferrer() {
  // Check if there is a referrer. If not, there is no need to store organic tracking as there is nothing to track
  if (!document.referrer) {
    return false;
  }

  // Try and convert the referrer to a URL. If it fails, there is no need to store organic tracking as there is nothing to track
  let referrerUrl: URL;
  try {
    referrerUrl = new URL(document.referrer);
  } catch {
    return false;
  }

  // Don't store organic tracking if the referrer is from raylo.com or raylopay.com, as it's not the first page the user has visited on the site in the current session
  if (
    referrerUrl.hostname.endsWith('raylo.com') ||
    referrerUrl.hostname.endsWith('raylopay.com')
  ) {
    return false;
  }

  // If all the above checks pass, the referrer is from a different domain, so it's likely an organic search
  return true;
}

function paramsContainUTMParams(params: CookieItem) {
  // Return if url contains any of the possible params that tracking is interested for
  return (
    params.utm_category ||
    params.utm_provider ||
    params.utm_partner ||
    params.utm_campaign ||
    params.utm_medium ||
    params.utm_source ||
    params.utm_content ||
    params.utm_term
  );
}

function constructOrganicTracking(params: CookieItem) {
  // If the URL contains UTM params, assume it's not an organic search
  // Also check if the referrer is from a different domain and is valid
  if (
    !paramsContainUTMParams(params) &&
    canStoreOrganicTrackingBasedOnReferrer()
  ) {
    // Check if there is an existing organic tracking entry in the cookie
    const existingOrganicTracking = fetchExistingCollection(
      TRACKING_COOKIE_NAME,
    ).find((element) => element.category === trackingKeys.ORGANIC_SEARCH);

    // If there is no existing organic tracking entry, create a new one
    if (!existingOrganicTracking) {
      return {
        category: trackingKeys.ORGANIC_SEARCH,
        ...getSharedProperties(),
      };
    }
  }

  return {};
}

function mapProviderParams(params: CookieItem) {
  // Return if url does not contain any of the possible params that tracking is interested for
  if (!paramsContainUTMParams(params)) {
    return {};
  }

  const categoryValue = params.utm_category?.toUpperCase();

  const tracking: CookieItem = {
    campaignReference: params.utm_campaign,
    category: categoryValue,
    contentReference: params.utm_content,
    mediumReference: params.utm_medium,
    partnerReference: params.utm_partner,
    provider: params.utm_provider,
    sourceReference: params.utm_source,
    term: params.utm_term,
    ...getSharedProperties(),
  };

  if (tracking.provider === 'awin' && params.awc) {
    tracking.visitorReference = params.awc;
  }

  if (tracking.provider === 'post_affiliate_pro') {
    tracking.visitorReference = extractPostAffiliateProVisitorId();
  }

  if (
    params.gclid &&
    tracking.provider === 'google' &&
    tracking.category === 'ADS'
  ) {
    tracking.visitorReference = params.gclid;
  }

  if (params.msclkid) {
    tracking.visitorReference = params.msclkid;
  }

  if (params.ttclid) {
    tracking.provider = tracking.provider || 'tiktok';
    tracking.visitorReference = params.ttclid;
  }

  if (params.fbclid) {
    tracking.provider = tracking.provider || 'facebook';
    tracking.visitorReference = params.fbclid;

    const fbClickId = getCookie('_fbc');
    if (fbClickId) {
      tracking.interactionReference = fbClickId;
    }

    const fbBrowserId = getCookie('_fbp');
    if (fbBrowserId) {
      tracking.clientSessionReference = fbBrowserId;
    }
  }

  compactObject(tracking);
  return tracking;
}

function extractPostAffiliateProVisitorId() {
  return getCookie('PAPVisitorId');
}

function bakeCookie(cookieName: string, collection: any[]) {
  const newCollection: CookieItem[] = [];
  collection.forEach(function (member) {
    if (Object.keys(member).length !== 0) {
      newCollection.push(member);
    }
  });

  if (newCollection.length === 0) {
    return;
  }

  const date = new Date();
  date.setMonth(date.getMonth() + 1);

  const concatenatedCollection =
    fetchExistingCollection(cookieName).concat(newCollection);

  const cookie = `${cookieName}=${JSON.stringify(concatenatedCollection)}; expires=${date.toUTCString()}; domain=${getHostname()}; path=/`;
  document.cookie = cookie;
}

function fetchExistingCollection(cookieName: string): CookieItem[] {
  const cookieValue = getCookie(cookieName);

  try {
    return JSON.parse(cookieValue);
  } catch {
    return [];
  }
}

// function for reading a cookie
export function getCookie(cookieName: string) {
  const regex = new RegExp(`(^| )${cookieName}=([^;]+)(;|$)`);
  const match = document.cookie.match(regex);
  if (match) {
    return match[2];
  }
  return '';
}

function getHostname() {
  let hostname = window.location.hostname;

  if (hostname.includes('.raylo.com')) {
    // Drop first subdomain
    const values = hostname.split('.');
    values.shift();
    hostname = values.join('.');
  }

  return hostname;
}

function compactObject(element: Record<string, any>) {
  // Remove undefined attributes
  Object.keys(element).forEach(function (key) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    element[key] === undefined && delete element[key];
  });
}

function initTracking() {
  const urlSearch = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearch);

  const tracking = mapProviderParams(params);
  const organicTracking = constructOrganicTracking(params);
  const googleTracking = constructGoogleTracking(params);
  const rayloExperimentTrackings = fetchExistingCollection('raylo_experiments');
  const trackings = rayloExperimentTrackings.concat([
    tracking,
    organicTracking,
    googleTracking,
  ]);

  if (trackings) {
    bakeCookie(TRACKING_COOKIE_NAME, trackings);
  }
}

export default initTracking;
